import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { Module, ModuleBody, Tab, Tabs, Text, Wrapper } from '@wfp/ui';
import { CardTabsViewProps } from './CardTabs';
import './CardTabs.scss';

export const CardTabsView = ({
  activeTabIdx,
  tabLabels,
  onTabChange,
  heading,
  headingKind,
  cardContents
}: CardTabsViewProps) => (
  <Wrapper pageWidth="lg">
    {heading && (
      <Text kind={headingKind}>
        <T _str={heading} />
      </Text>
    )}
    <Tabs selected={activeTabIdx} className="tabs" disableAnimation>
      {tabLabels.map((tabLabel, tabIdx) => (
        <Tab
          key={`tab-${tabLabel}`}
          label={t(tabLabel)}
          onClick={() => onTabChange(tabIdx)}
          href=""
          role="presentation"
          selected={activeTabIdx === tabIdx}
          tabIndex={tabIdx}
        />
      ))}
    </Tabs>
    <Module className="card-tabs-module">
      <ModuleBody>{cardContents[activeTabIdx]}</ModuleBody>
    </Module>
  </Wrapper>
);
